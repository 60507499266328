export default function getCustomJSON(intro) {
  return {
    soundtrack: {
      startTime: 0,
      source: '',
    },
    images: [
      {
        path: 'assets[0]',
        source: 'https://videomatik-files.s3.us-east-2.amazonaws.com/videomatik-dev/templates/the-last-of-us-216c22g/assets/2qvbaw5c--compressed-png.jpg',
      },
    ],
    version: '1',
    texts: [
      {
        path: 'assets[6].layers[0].t.d.k[0]',
        fontName: 'PressGothic',
        fontFamily: 'Press Gothic',
        fontWeight: '400',
        fontStyle: 'Regular',
        fontAscent: 81.3995361328125,
        fontSize: 220,
        lineHeight: 200,
        justification: 'LEFT',
        value: intro.endingTitle,
        fillColor: '#000000',
        time: null,
        hidden: null,
      },
      ...intro.titles.map((title, index) => ({
        path: `assets[7].layers[${19 - index}].t.d.k[0]`,
        fontName: 'PressGothic',
        fontFamily: 'Press Gothic',
        fontWeight: '400',
        fontStyle: 'Regular',
        fontAscent: 81.3995361328125,
        fontSize: 44,
        lineHeight: 52.8000030517578,
        justification: 'CENTER',
        value: title,
        fillColor: '#ffffff',
        time: null,
        hidden: null,
      })),
    ],
    shapes: [],
    videos: [
      {
        path: 'assets[2]',
        source: 'https://videomatik-files.s3.us-east-2.amazonaws.com/videomatik/templates/the-last-of-us-unstable/assets/tlou-compressed.mp4',
      },
    ],
  }
}
