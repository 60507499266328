import React from 'react'
import PropTypes from 'prop-types'

import SWRConfig from './SWRConfig'
import AppContent from './AppContent'

export default function App({ fallback, ...props }) {
  return (
    <SWRConfig fallback={fallback}>
      <AppContent {...props} />
    </SWRConfig>
  )
}

App.propTypes = {
  fallback: PropTypes.object,
}
