import React, { useState, useCallback } from 'react'
import Image from 'next/image'

import NavbarMenu from './NavbarMenu'
import ExternalLink from './ExternalLink'

export default function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const toggleMenu = useCallback(() => {
    setIsMenuOpen(!isMenuOpen)
  }, [setIsMenuOpen, isMenuOpen])

  return (
    <nav className="navbar">
      <div className="content">
        <div className="logo-container">
          <ExternalLink href="https://kassellabs.io">
            <Image
              src="/images/kassel-labs-logo.png"
              alt="Kassel Labs Logo"
              height={20}
              width={160}
            />
          </ExternalLink>
        </div>
        <label htmlFor="show-menu" className="show-menu">
          <span />
          <span />
          <span />
          <input onChange={toggleMenu} type="checkbox" id="show-menu" role="button" />
        </label>
        <NavbarMenu />
      </div>
      {isMenuOpen && (
        <NavbarMenu />
      )}
      <style jsx global>
        {`
        $tablet = 'only screen and (min-device-width : 768px)';

        .navbar {
          min-height: 50px;
          background-color: #141414;
          font-family: Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;

          .content {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 20px;
          }

          .content > .logo-container {
            margin: 13px 0;
          }

          .content > .menu {
            display: none;

            @media $tablet {
              display: block;
            }
          }

          .show-menu {
            @media $tablet {
              display: none;
            }

            input[type=checkbox] {
              display: none;
            }

            span {
              display: block;
              width: 22px;
              height: 2px;
              border-radius: 1px;
              background-color: white;

              &:not(:first-child) {
                margin-top: 4px;
              }
            }
          }
        }
        `}
      </style>
    </nav>
  )
}
