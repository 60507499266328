import axios from 'axios'
import { backOff } from 'exponential-backoff'

const introApiRequest = ({ path, ...options }) => backOff(() => axios.request({
  baseURL: process.env.INTRO_API_URL,
  url: path,
  headers: {
    'Content-Type': 'application/json',
  },
  ...options,
}), {
  retry: (error) => {
    const statusCode = error.response?.status || 0
    return statusCode >= 500 || statusCode < 400
  },
})

export default introApiRequest
