import React from 'react'
import PropTypes from 'prop-types'
import { Link } from '@mui/material'

export default function ExternalLink({ children, ...props }) {
  return (
    <Link {...props} target="_blank" rel="noopener noreferrer">
      { children }
    </Link>
  )
}

ExternalLink.propTypes = {
  children: PropTypes.node,
}
