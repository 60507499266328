import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { SWRConfig as DefaultSWRConfig } from 'swr'

import introApiRequest from './intro-api-request'

export default function SWRConfig({ fallback, children }) {
  const fetcher = useCallback(async (resource) => {
    const response = await introApiRequest({
      path: resource,
    })
    return response.data
  }, [])

  return (
    <DefaultSWRConfig
      value={{
        fallback,
        fetcher,
        revalidateIfStale: false,
        revalidateOnFocus: true,
        revalidateOnReconnect: true,
      }}
    >
      { children }
    </DefaultSWRConfig>
  )
}

SWRConfig.propTypes = {
  fallback: PropTypes.object,
  children: PropTypes.node,
}
