import React from 'react'
import PropTypes from 'prop-types'

import Head from './Head'
import Navbar from './Navbar'

export default function Layout({ previewImage, children }) {
  return (
    <div>
      <Head
        previewImage={previewImage}
      />
      <Navbar />
      <div>
        { children }
      </div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node,
  previewImage: PropTypes.string,
}
