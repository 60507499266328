import { useState, useEffect } from 'react'

const useNavigator = () => {
  // If need to handle any safari issue in the future
  // const [isSafari, setIsSafari] = useState(false)

  // useEffect(() => {
  //   const checkSafari = /^((?!chrome|android).)*safari/i.test(navigator?.userAgent || '')
  //   setIsSafari(checkSafari)
  // })

  const [isChromeIos, setIsChromeIos] = useState(false)
  const [isSafariIos, setIsSafariIos] = useState(false)

  useEffect(() => {
    const checkChromeIos = /CriOS/i.test(navigator.userAgent || '')

    const ua = window.navigator.userAgent
    const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i)
    const webkit = !!ua.match(/WebKit/i)
    const iOSSafari = iOS && webkit && !ua.match(/CriOS/i)

    setIsChromeIos(checkChromeIos)
    setIsSafariIos(iOSSafari)
  })

  return {
    // isSafari,
    isChromeIos,
    renderScreenshotLocal: !isChromeIos && !isSafariIos,
  }
}

export default useNavigator
