import React from 'react'
import PropTypes from 'prop-types'
import { Button as MUIButton, CircularProgress } from '@mui/material'

const LoadingSpinner = ({ children }) => (
  <div className="loading-spinner">
    <div className="invisible-content">
      { children }
    </div>
    <div className="spinner">
      <CircularProgress size={22} color="secondary" />
    </div>
    <style jsx>
      {`
      .loading-spinner {
        position: relative;

        // Keeps the button at the same size as before the loading
        .invisible-content {
          opacity: 0;
        }

        .spinner {
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
        }
      }
      `}
    </style>
  </div>
)

LoadingSpinner.propTypes = {
  children: PropTypes.node,
}

export default function Button({ loading, children, ...props }) {
  return (
    <MUIButton
      {...props}
      disabled={loading}
    >
      {
        loading
          ? (
            <LoadingSpinner>
              { children }
            </LoadingSpinner>
          )
          : children
      }
    </MUIButton>
  )
}

Button.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.node,
}
