import React from 'react'
import { useTheme } from '@mui/material/styles'

import ExternalLink from '../layout/ExternalLink'

export default function IconLink({ ...props }) {
  const theme = useTheme()
  return (
    <>
      <ExternalLink {...props} className="icon-link" />
      <style jsx global>
        {`
        .icon-link {
          svg {
            fill: ${theme.typography.link.color};
            transition: fill 0.1s ease-in-out;

            &:hover {
              fill: ${theme.palette.secondary.main} !important;
            }
          }

        }
        `}
      </style>
    </>
  )
}
