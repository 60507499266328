import React from 'react'
import PropTypes from 'prop-types'
import {
  Dialog,
  DialogTitle,
  IconButton,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

export default function Modal({
  title,
  children,
  onClose,
  ...props
}) {
  return (
    <Dialog
      scroll="body"
      onClose={onClose}
      {...props}
    >
      <DialogTitle>
        { title }
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      {children}
    </Dialog>
  )
}

Modal.propTypes = {
  title: PropTypes.node,
  onClose: PropTypes.func,
  children: PropTypes.node,
}
